import React from 'react'
import { FiGithub, FiMail, FiMapPin } from "react-icons/fi";
import HeroImage from "../../assets/face2.jpg"
import Carousel from '../Carousel/Carousel';


function Hero({ heroRef }) {
    return (
        <div ref={heroRef} className="w-full h-full flex flex-col items-start justify-start mb-4">
            <div className="p-5 mb-2 text-center w-full">
                <h1 className="text-3xl md:text-8xl font-black uppercase">The only constant is Change.</h1>
            </div>
            <section className="w-full grid grid-cols-auto md:grid-cols-12 grid-rows-12 gap-2 mb-4">
                <div className="md:col-span-3 md:col-start-2 flex flex-col w-full md:border-r-2 border-r-black px-2">
                    <h2 className="font-semibold text-3xl mb-2">Who is Kah Shin?</h2>
                    <p className="open-sans font-extralight leading-tight">
                        Kah Shin is an aspiring leader who actively seeks out to constantly learn and apply technology to augment our society. He has worked on projects that positively impacted the environment and have mentored individuals who are keen on leadership and learning.
                    </p>
                    <p className="open-sans font-extralight leading-tight">

                    </p>

                </div>
                <div className="px-2 md:col-span-4">
                    <img className="grayscale sepia hover:grayscale-0 hover:sepia-0 rounded-md border-l-2 border-b-4 border-black" src={HeroImage} alt="" srcset="" />
                </div>
                <div className="md:col-span-3 md:border-l-2 border-l-black grid grid-cols-auto grid-rows-3 px-2">
                    <h3 className="col-span-3 font-bold text-5xl md:text-7xl text-start">Student.</h3>
                    <h3 className="col-span-4 font-bold text-5xl md:text-7xl text-center">Developer.</h3>
                    <h3 className="col-span-3 col-start-2 font-bold text-5xl md:text-7xl text-end">Hobbyist.</h3>
                </div>
            </section>
            <hr className="border border-black w-5/6 mx-auto my-2" />
            <div className="h-auto w-full">
                {/* <Carousel /> */}
            </div>


        </div>
    )
}

export default Hero