import { React, useEffect } from 'react'
// import Placeholder from "../../assets/pandaWhite.png"

function ProjectCard({ info, index }) {



    return (
        <div className={`${index % 3 ? "" : "md:col-start-2"} ${index == 0 ? 'md:col-start-2' : ""} col-span-1 ${index % 2 ? (index % 3 ? "md:col-span-4" : "md:col-span-3") : "md:col-span-3"} h-auto max-h-[500px] relative hover:shadow-lg border-l-2 border-b-4 rounded-md border-t border-r border-black`}>

            <div className="w-full h-20 bg-orange-300"></div>
            <div className="w-full h-full px-3 py-2.5 flex flex-col gap-4 left-2">
                <h1 className="text-3xl font-medium font-mono">{info.name}</h1>
                <p className="text-truncate open-sans leading-tight">{info.description}</p>
                <a href={info.html_url} target="_blank" className="rounded-lg border border-black w-fit px-2 py-1.5 open-sans hover:cursor-pointer hover:shadow-md hover:bg-black transition-all duration-300 hover:text-[#ffedd5] hover:rounded-full ease-in-out text-md ">Github Link</a>
            </div>
        </div>
    )
}

export default ProjectCard