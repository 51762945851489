import { React, useRef, useEffect, useState } from 'react'
import Experience from '../components/ExperienceSection/Experience'
import Footer from '../components/Footer/Footer'
import Hero from '../components/HeroSection/Hero'
import Navbar from '../components/navbar/Navbar'
import OverlayLogo from '../components/overlayLogo/OverlayLogo'
import Projects from '../components/Projects/Projects'
import { FiArrowUp } from "react-icons/fi";



function Home() {

  let heroRef = useRef(null)
  let experienceRef = useRef(null)
  let projectsRef = useRef(null)
  let footerRef = useRef(null)

  const [overlay, setOverlay] = useState(true)

  let displayOverlay = () => {
    return (
      <div id="overlay" className={`overscroll-contain fade-out absolute bg-orange-100 flex justify-center items-center w-screen h-screen z-50`}>
        <OverlayLogo />
      </div>
    )


  }


  useEffect(() => {
    displayOverlay()
    // when overlay is true, disable scrolling
    if (setOverlay) {
      document.body.style.overflow = 'hidden'
    }

    setTimeout(() => {
      // remove overlay
      setOverlay(false)
      // enable scrolling
      document.body.style.overflow = 'auto'

    }, 4000);
  }, [])


  return (
    <div className="scroll-smooth relative h-full w-full">
      {
        overlay ?
          displayOverlay()
          : null

      }
      
      <Navbar heroRef={heroRef} experienceRef={experienceRef} projectsRef={projectsRef} footerRef={footerRef} />
      <Hero heroRef={heroRef} className="snap-start" />
      <Experience experienceRef={experienceRef} />
      <Projects projectsRef={projectsRef} className="snap-start" />
      <Footer heroRef={heroRef} experienceRef={experienceRef} projectsRef={projectsRef} footerRef={footerRef} className="snap-start" />
      <div className="fixed z-10 right-5 bottom-3 bg-black text-white p-2 rounded-full text-xl md:text-3xl opacity-50 hover:opacity-100 focus:opacity-100 hover:cursor-pointer hover:scale-110 hover:-translate-y-1 ease-in-out transition-all duration-200" onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }}>
        <FiArrowUp />
      </div>
    </div>
  )
}

export default Home