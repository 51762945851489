import { React, useEffect, useState } from 'react'
import { FiMenu, FiX, FiGithub, FiMail } from "react-icons/fi";

function Navbar({ heroRef, experienceRef, projectsRef, footerRef }) {

    const [showMenu, setShowMenu] = useState(false)

    const scrollToView = (reference) => {
        reference.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <ul className="relative w-full flex justify-between md:justify-center items-center md:px-20 border-b-4 border-b-black">
            <li className="capitalize md:border-l-black md:border-l p-3 w-1/2 h-full text-5xl">angKS</li>
            <li className="hidden hover:bg-black transition-all duration-200 hover:text-[#ffedd5] ease-in-out md:block capitalize open-sans md:border-l-black md:border-l p-5 w-1/6 h-full text-lg hover:cursor-pointer" onClick={() => { scrollToView(experienceRef) }}>About</li>
            <li className="hidden hover:bg-black transition-all duration-200 hover:text-[#ffedd5] ease-in-out md:block capitalize open-sans md:border-l-black md:border-l p-5 w-1/6 h-full text-lg hover:cursor-pointer" onClick={() => { scrollToView(projectsRef) }}>Projects</li>
            <div className="hidden md:flex capitalize open-sans md:border-x-black md:border-x p-5 w-1/6 h-full text-lg hover:cursor-pointer justify-between items-center" onClick={() => { scrollToView(footerRef) }}>
                <h2 className="open-sans">Contact</h2>
                <div className="flex w-1/2 items-center justify-around">
                    <a className="hover:shadow-md hover:bg-black transition-all duration-200 hover:text-[#ffedd5] ease-in-out hover:-translate-y-1 hover:scale-110 px-1.5 py-1.5 rounded-full" href="mailto:hellokahshin@gmail.com"><FiMail /></a>
                    <a className="hover:shadow-md hover:bg-black transition-all duration-200 hover:text-[#ffedd5] ease-in-out hover:-translate-y-1 hover:scale-110 px-1.5 py-1.5 rounded-full" href="https://www.github.com/angks"><FiGithub /></a>
                </div>
            </div>
            <li className="md:hidden w-1/6 p-5 text-2xl h-full hover:cursor-pointer" onClick={() => { setShowMenu(!showMenu) }}>
                <FiMenu />
            </li>
            {
                showMenu ?
                    <div className="absolute z-10 shadow-sm bg-orange-100 top-0 inset-x-0">
                        <div className="w-full flex justify-between items-center border-b-4 border-b-black">
                            <li className="capitalize p-3 w-1/2 h-full text-5xl">angKS</li>
                            <li className="p-5 w-1/6 text-2xl h-full hover:cursor-pointer" onClick={() => { setShowMenu(!showMenu) }}>
                                <FiX />
                            </li>
                        </div>
                        <li className="md:block capitalize open-sans p-5 w-full border-b border-b-transparent h-full text-lg hover:cursor-pointer hover:border-b hover:border-b-black" onClick={() => {
                            scrollToView(experienceRef)
                            setShowMenu(false)

                        }}>About</li>
                        <li className="md:block capitalize open-sans p-5 w-full border-b border-b-transparent h-full text-lg hover:cursor-pointer hover:border-b hover:border-b-black" onClick={() => {
                            scrollToView(projectsRef)
                            setShowMenu(false)

                        }}>Projects</li>
                        <li className="md:block capitalize open-sans p-5 w-full border-b border-b-transparent h-full text-lg hover:cursor-pointer hover:border-b hover:border-b-black" onClick={() => {
                            scrollToView(footerRef)
                            setShowMenu(false)

                        }}>Contact</li>
                    </div>
                    : ""
            }

        </ul>
    )
}

export default Navbar