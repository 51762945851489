import React from 'react'
import { Chrono } from "react-chrono";
import Carousel from '../Carousel/Carousel';

function Experience({ experienceRef }) {

    let experience_items = [{
        date: "Jan 2016 - Sept 2019",
        title: "Ang Mo Kio Secondary School",
        subtitle: "GCE O Level",
        description: "lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quidem.",
        cta: "",

    },
    {
        date: "Apr 2020 - Present",
        title: "Singapore Polytechnic",
        subtitle: "Diploma in Applied AI & Analytics",
        description: "lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quidem.",
        cta: "https://www.sp.edu.sg/soc/courses/full-time-diplomas/applied-AI-and-analytics/overview",

    },
    {
        date: "Mar 2022 - Present",
        title: "FWD Insurance",
        subtitle: "Software Engineering Intern",
        description: "lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quidem.",
        cta: "",

    },


    ]

    return (
        <div ref={experienceRef} className="w-full h-fit max-h-screen mb-4 overflow-scroll">
            <div className="p-5 mb-2 text-start border-y border-y-black w-full border-dashed">
                <h1 className="text-3xl md:text-5xl font-black uppercase">Experience</h1>
            </div>
            <section className="md:flex items-center justify-center gap-3 w-3/4 mx-auto">
                <ol className="relative h-fit w-full md:border-l border-black dark:border-gray-700 max-w-[1000px]">
                    {
                        experience_items.map((item, index) => {
                            return (
                                <li key="index" className="mb-10 ml-4 mr-2 max-h-[350px] hover:shadow-lg">
                                    <div className="absolute w-3 h-3 md:bg-white rounded-full -left-1.5 md:border border-black dark:border-gray-900 dark:bg-gray-700"></div>
                                    <div className="flex flex-col md:flex-row w-full p-2 gap-2 border border-l-2 border-b-4 border-black">
                                        <div className="md:w-1/3">
                                            <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-500 open-sans">{item.date}</time>
                                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white open-sans">{item.title}</h3>
                                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400 open-sans">{item.subtitle}</p>

                                            {item.cta ? <a href={item.cta} target="_blank" class="open-sans inline-flex items-center px-4 py-2 text-sm font-medium text-black bg-transparent border border-black rounded-lg hover:bg-black hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Learn more <svg class="w-3 h-3 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></a> : ""}
                                        </div>
                                        <p className="p-2 open-sans text-sm flex items-center justify-center border-l-2 border-black break-normal max-h-[300px] text-ellipsis overflow-hidden">
                                            {item.description}
                                        </p>
                                    </div>
                                </li>
                            )
                        })
                    }


                </ol>
            </section>

        </div>
    )
}

export default Experience