import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/Home'

function App() {
  return (
    <div className="App h-full w-full bg-orange-100">
        <Router> 
            {/* <Navbar /> */}
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </Router>
    </div>
  )
}

export default App