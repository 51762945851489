import { React, useState, useEffect } from 'react'
import ProjectCard from './ProjectCard'
import axios from "axios"

function Projects({ projectsRef }) {

    const [showAll, setShowAll] = useState(false)
    const [projects, setProjects] = useState([])



    useEffect(() => {

        axios.get('https://api.github.com/users/angks/repos').then(res => {
            if (res.data.length > 0) {
                // get all public repository
                const public_repos = res.data.filter(repo => !repo.fork || !repo.private)
                setProjects(public_repos)
            }
        })

    }, [])


    return (
        <div ref={projectsRef} className={`w-full ${showAll ? "h-100" : "max-h-screen"} mb-8 overflow-hidden relative`}>
            <div id="projects" className="p-5 mb-2 text-start border-y border-y-black w-full border-dashed">
                <h1 className="text-3xl md:text-5xl font-black uppercase">Projects</h1>
            </div>
            <section className="w-3/4 md:w-fit mx-auto content-center grid grid-cols-1 md:grid-cols-12 py-5 gap-2 overflow-y-scroll">
                {
                    projects ? projects.map((proj, index) => {
                        return <ProjectCard key={index} index={index} info={proj} />
                    }) : <h1 className="text-4xl font-bold mx-auto my-10 col-span-12 capitalize">No projects to show.</h1>
                }

            </section>
            <div className="px-4 py3.5 my-6 text-3xl border-l-2 border border-b-4 border-transparent hover:border-l hover:border-b shadow-xl"></div>
            {
                projects ?
                    <div className="px-4 py3.5 text-3xl rounded-md middle bg-red-300 hover:cursor-pointer border-l-2 border border-b-4 border-black hover:border-l hover:border-b" onClick={() => { setShowAll(!showAll) }}>{showAll ? "Hide" : "More"}</div>
                    : ""
            }
        </div>
    )
}

export default Projects