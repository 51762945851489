import React from 'react'
import QRcode from "../../assets/resumeQR.png"
import SpotifyQR from "../../assets/spotify-qr.png"


function Footer({ heroRef, experienceRef, projectsRef, footerRef }) {

    const scrollToView = (reference) => {
        reference.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div ref={footerRef} id="footer" className="relative w-full h-fit pt-7 pb-2 px-3">

            <div className="flex flex-col md:flex-row w-full gap-3 justify-center items-start p-2">
                <div id="resume" className="flex flex-col items-center md:items-start h-full gap-3 justify-between w-full md:w-1/3 hover:cursor-pointer">
                    <h1 className="text-4xl font-black" onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }}>KAHSHIN</h1>
                    <a href="https://drive.google.com/file/d/1FGJF03knWlGFdvS7HdlUm8NSfGoe7Ls2/view?usp=sharing" target="_blank" className="h-auto text-center w-fit transition-all delay-100 hover:-translate-y-1 hover:scale-110">
                        <img className="h-28 shadow-xl" src={QRcode} alt="Resume" />
                        <span className="">Resume</span>
                    </a>

                </div>
                
                <div className="flex flex-col w-full justify-start h-full gap-3 md:w-1/4">
                    <a href="https://www.linkedin.com/in/kahshinang" target="_blank" className="border rounded-md border-l-2 border-b-4 hover:border-blue-800 hover:border-blue-800 border-black hover:bg-blue-800 hover:text-white py-2.5 px-3 text-lg font-bold open-sans transition-all delay-200 hover:-translate-y-1 hover:scale-110">Linkedin</a>
                    <a href="https://www.github.com/angks" target="_blank" className="text-lg rounded-md font-bold border border-l-2 border-b-4 hover:border-black hover:border-black border-black hover:bg-black hover:text-white py-2.5 px-3 open-sans transition-all delay-200 hover:-translate-y-1 hover:scale-110">Github</a>
                    <a href="https://compiler.kahshin.codes" target="_blank" className="text-lg rounded-md font-bold border border-l-2 border-b-4 hover:border-slate-700 hover:border-slate-700 border-black hover:bg-slate-700 hover:text-orange-300 py-2.5 px-3 open-sans transition-all delay-200 hover:-translate-y-1 hover:scale-110">Online Compiler</a>
                </div>

                <div className="flex flex-col w-full justify-between h-full items-center gap-3 md:w-1/3">

                    <div className="flex justify-center items-end w-full h-full gap-3 md:w-1/3">
                        <span className="text-xl open-sans px-3 py-2.5 hover:cursor-pointer" onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            })
                        }}>Home</span>
                        <span className="text-xl open-sans px-3 py-2.5 hover:cursor-pointer" onClick={() => scrollToView(experienceRef)}>About</span>
                        <span className="text-xl open-sans px-3 py-2.5 hover:cursor-pointer" onClick={() => scrollToView(projectsRef)}>Projects</span>
                    </div>

                    <div className="grow"></div>

                    <a className="w-1/3" href="https://open.spotify.com/user/733tosem74ht05oeeke593lxu?si=CCClIoeXQPKpCFXXSOHInw" target="_blank"><img src={SpotifyQR} alt="" className="grayscale invert" /></a>


                </div>

                


            </div>
            <div className="flex open-sans items-center justify-center p-2 mb-5 w-full border-t border-black">
                Created by &nbsp;<a className="open-sans uppercase font-semibold" href="https://www.linkedin.com/in/kahshinang">ang kah shin</a>
            </div>

        </div>
    )
}

export default Footer